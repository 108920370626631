<template>
<link  rel="stylesheet" href="https://unpkg.com/bulma-modal-fx/dist/css/modal-fx.min.css" />
<!-- <nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" href="/">
      brand
    </a>

    <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu">
    <div class="navbar-start">
      <a class="navbar-item">
        OpenSea
      </a>

      <a class="navbar-item">
        Discord
      </a>

      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">
          More
        </a>

        <div class="navbar-dropdown">
          <a class="navbar-item">
            About
          </a>
          <a class="navbar-item">
            Jobs
          </a>
          <a class="navbar-item">
            Contact
          </a>
          <hr class="navbar-divider">
          <a class="navbar-item">
            Report an issue
          </a>
        </div>
      </div>
    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <a class="button is-primary">
            <strong>Sign up</strong>
          </a>
          <a class="button is-light">
            Log in
          </a>
        </div>
      </div>
    </div>
  </div>
</nav> -->
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<script lang="ts">

</script>
<style lang="scss">
@import "../node_modules/bulma/bulma.sass";

// QuickSand
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

$baseBorderRadius : 1rem;

$colorDogBorder : #000;
$colorDogBodyLight : #ffe5ad;
$colorDogBodyDark : #dba071;
$maxWidth: 1280px;

//$outerStroke: rgb(255, 255, 255) 4px 0px 0px, rgb(255, 255, 255) 3.87565px 0.989616px 0px, rgb(255, 255, 255) 3.51033px 1.9177px 0px, rgb(255, 255, 255) 2.92676px 2.72656px 0px, rgb(255, 255, 255) 2.16121px 3.36588px 0px, rgb(255, 255, 255) 1.26129px 3.79594px 0px, rgb(255, 255, 255) 0.282949px 3.98998px 0px, rgb(255, 255, 255) -0.712984px 3.93594px 0px, rgb(255, 255, 255) -1.66459px 3.63719px 0px, rgb(255, 255, 255) -2.51269px 3.11229px 0px, rgb(255, 255, 255) -3.20457px 2.39389px 0px, rgb(255, 255, 255) -3.69721px 1.52664px 0px, rgb(255, 255, 255) -3.95997px 0.56448px 0px, rgb(255, 255, 255) -3.97652px -0.432781px 0px, rgb(255, 255, 255) -3.74583px -1.40313px 0px, rgb(255, 255, 255) -3.28224px -2.28625px 0px, rgb(255, 255, 255) -2.61457px -3.02721px 0px, rgb(255, 255, 255) -1.78435px -3.57996px 0px, rgb(255, 255, 255) -0.843183px -3.91012px 0px, rgb(255, 255, 255) 0.150409px -3.99717px 0px, rgb(255, 255, 255) 1.13465px -3.8357px 0px, rgb(255, 255, 255) 2.04834px -3.43574px 0px, rgb(255, 255, 255) 2.83468px -2.82216px 0px, rgb(255, 255, 255) 3.44477px -2.03312px 0px, rgb(255, 255, 255) 3.84068px -1.11766px 0px, rgb(255, 255, 255) 3.9978px -0.132717px 0px;
$outerStroke: rgb(255, 255, 255) 6px 0px 0px, rgb(255, 255, 255) 5.91686px 0.995377px 0px, rgb(255, 255, 255) 5.66974px 1.96317px 0px, rgb(255, 255, 255) 5.2655px 2.87655px 0px, rgb(255, 255, 255) 4.71532px 3.71022px 0px, rgb(255, 255, 255) 4.03447px 4.44106px 0px, rgb(255, 255, 255) 3.24181px 5.04883px 0px, rgb(255, 255, 255) 2.35931px 5.51667px 0px, rgb(255, 255, 255) 1.41143px 5.83163px 0px, rgb(255, 255, 255) 0.424423px 5.98497px 0px, rgb(255, 255, 255) -0.574341px 5.97245px 0px, rgb(255, 255, 255) -1.55719px 5.79441px 0px, rgb(255, 255, 255) -2.49688px 5.45578px 0px, rgb(255, 255, 255) -3.36738px 4.96596px 0px, rgb(255, 255, 255) -4.14455px 4.33852px 0px, rgb(255, 255, 255) -4.80686px 3.59083px 0px, rgb(255, 255, 255) -5.33596px 2.74364px 0px, rgb(255, 255, 255) -5.71718px 1.8204px 0px, rgb(255, 255, 255) -5.93995px 0.84672px 0px, rgb(255, 255, 255) -5.99811px -0.150428px 0px, rgb(255, 255, 255) -5.89004px -1.14341px 0px, rgb(255, 255, 255) -5.61874px -2.1047px 0px, rgb(255, 255, 255) -5.19172px -3.00766px 0px, rgb(255, 255, 255) -4.62082px -3.82727px 0px, rgb(255, 255, 255) -3.92186px -4.54081px 0px, rgb(255, 255, 255) -3.11421px -5.12852px 0px, rgb(255, 255, 255) -2.22026px -5.57409px 0px, rgb(255, 255, 255) -1.26477px -5.86518px 0px, rgb(255, 255, 255) -0.274238px -5.99373px 0px, rgb(255, 255, 255) 0.723898px -5.95617px 0px, rgb(255, 255, 255) 1.70197px -5.75355px 0px, rgb(255, 255, 255) 2.63288px -5.39147px 0px, rgb(255, 255, 255) 3.49082px -4.87998px 0px, rgb(255, 255, 255) 4.25202px -4.23324px 0px, rgb(255, 255, 255) 4.89538px -3.46919px 0px, rgb(255, 255, 255) 5.40307px -2.60899px 0px, rgb(255, 255, 255) 5.76102px -1.67649px 0px, rgb(255, 255, 255) 5.95932px -0.697531px 0px;

$colorBackgroundSkyDay:#70dcf4;


// @keyframes bgscroll { 0% {background-position: 0 0;} 100% {background-position: -100vw 0 ;}}


body {
  overflow:hidden;
  background-color:$colorBackgroundSkyDay;
  // background-image:url('assets/background-cloud.png');
  background-size:100vw auto;
  background-repeat:repeat-x;
  background-attachment: fixed;
  font-family: Arial, Helvetica, sans-serif;
  // animation: bgscroll 15s linear infinite;
}


.content {
  font-family: 'Quicksand', sans-serif;
}


.adog--global {
  font-size:1.5rem;
  padding:1em;
  box-sizing:border-box;
  width:100%;
  max-width:$maxWidth;
  margin:0 auto;
  section {
    margin: 1.5em 0;
  }

  .adog--global--firstrow {
    display:flex;
    @media screen and (max-width: 640px) {
      flex-direction:column;
    }

  }

  .adog--button {
    line-height:2em;
    background-color:$colorDogBodyDark;
    border:none;
    color:#fff;
    margin:0.2em;
    padding:0.25em 1em;
    font-weight:bold;
    font-size:1.75rem;
    @media screen and (max-width: 640px) {
      font-size:1.3rem;
    }
    border-radius:0.25em;
    box-shadow:0 0.075em 0 rgba(0,0,0,0.3);
    position: relative;
    text-align:center;
    top:0;
    font-family: 'Quicksand', sans-serif;
    flex-grow:1;
    transition:all 0.05s ease-in-out 0s;

    &.adog--button--opensea {
      background-color: #00bcd4;
    }

    &.adog--button--discord {
      background-color: #7289da;
    }

    &.adog--button--twitter {
      background-color: #1da1f2;
    }

    &.adog--button--instagram {
      background-color: #e1306c;
    }

    &:hover {
      top:-0.1em;
      box-shadow:0 0.175em 0 rgba(0,0,0,0.3);
    }

    &:active {
      top:0.1em;
      box-shadow:0 0 0 rgba(0,0,0,0.75);
    }

    .adog--button--icon {
      display:inline-block;
      vertical-align:middle;
      line-height:0;
      img {
        height:2em;
      }
    }
  }
}

.adog--box  , .content .adog--box{
  border:0.25rem solid;
  border-radius:$baseBorderRadius;
  border-color:$colorDogBorder;
  background-color:$colorDogBodyLight;
  text-shadow: $outerStroke;
  &.adog--box-story {
    line-height:1.5em;
    font-weight:500;
    position: relative;
    overflow:hidden;
    p {
      margin:1em 0;
      position: relative;
      z-index:1;
    }
    .adog--silhouette {
      position: absolute;
      right:0;
      bottom:-64px;
      width:256px;
      g {
        fill:$colorDogBodyDark;
      }
    }
    
  }
}

.title {
  font-family: 'Quicksand', sans-serif;
  font-weight:700;
  text-shadow: $outerStroke;
  color:$colorDogBodyDark;
  text-align:center;
}

.adog--avatar {
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: center;
  width:100%;
  > li {
    text-align:center;
    padding:0.5em;
    width:33%;
    max-width:240px;
    .avatar {
      display:inline-block;
      border:0.25rem solid $colorDogBorder;
      border-color:$colorDogBorder;
      background-color:$colorDogBodyLight;
      border-radius:100%;
      box-shadow: $outerStroke;
      overflow:hidden;
      width:calc(100% - 1em);
      margin:0 auto;
      img {
        display:block;

      }
    }
    .name {
      display:inline-block;
      text-shadow:$outerStroke;
      margin:0.1em 0;
    }
    .role {
      display:inline-block;
      background-color:$colorDogBodyDark;
      margin:0.5em 0;
      border-radius:1em;
      padding:0.1em 1em;
      font-size:75%;
      font-weight:bold;
      color:#fff;
    }
    .socialmedia {
      a {
        $size:1.5em;
        line-height:$size;
        box-sizing:border-box;
        display:inline-block;
        overflow:hidden;
        width:$size;
        height:$size;
        background-color:$colorDogBodyDark;
        color:#fff;
        border-radius:100%;
        margin:0.25em 0.1em;
        transition:all 0.2s cubic-bezier(0.42, -0.87, 0.41, 1.49);

        &.socialmedia--facebook {
          background-color:#3772E3;
        }

        &.socialmedia--twitter {
          background-color:#1DA1F2;
        }

        &.socialmedia--instagram {
          background-color: #E1306C;
        }

        &.socialmedia--youtube {
          background-color: #FF0000;
        }

        
        &:hover {
          transform:scale(1.2);
        }
      }
    }
  }
}

.adog--highlight {
  color:$colorDogBodyDark;
  font-weight:700;
}

.adog-qa {

  $bubbleRound : 1.5rem;
  $bubblePadding: 0.2em 1em;
  max-width:720px;
  margin:0 auto;

  dl , dl , dd {
    clear:both;
  }
  dl {
    padding:0.5em;
    &:after {
      content:"";
      display:block;
      clear:both;
    }

  }

  dt,dd {
    border-radius:$bubbleRound;
    padding:$bubblePadding;
    margin:0.25em 0;
    position:relative;
    max-width:90%;
    svg {
      position:absolute;
      width:1em;
      bottom:-0em;
    }
  }

  dt {
    background-color:$colorDogBodyDark;
    color:#fff;
    float:left;
    text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
    strong {
      color:inherit;
    }
    svg  {
      fill:$colorDogBodyDark;
      left:-0.4em;
    }
  }

  dd {
    background-color:$colorDogBodyLight;
    color:#333;
    text-align:right;
    float:right;
    svg  {
      fill:$colorDogBodyLight;
      right:-0.4em;
    }
    text-shadow: 0 1px 0 rgba(255,255,255,0.25);
  }

  dl.opened dt {
    
  }

}

.adog--gallery {
  img {
    max-width:70vw;
    max-height:400px;
    width:90%;
    border-radius:1rem;
    margin: 1rem 0;
    box-shadow: 0 1px 4px rgb(4 111 115 / 50%);
    border:2px solid #fff;
    box-sizing:border-box;
  }
  .carousel__prev , .carousel__next {
    background-color:$colorDogBodyDark;
  }
  .carousel__pagination {
    .carousel__pagination-item button{
      background-color:$colorDogBodyDark;
      opacity:0.2;
      &.carousel__pagination-button--active {
        opacity:1;
      }
    }
  }
}

#footer-main {
  background-color:$colorDogBodyDark;
  color:#fff;
  position: relative;
  padding:2em 0;
  text-align:center;
  font-weight:bold;
  font-size:90%;
  position: relative;
  z-index:2;
  .adog--silhouette {
    position: absolute;
    right:0;
    top:-64px;
    width:128px;
    g {
      fill:$colorDogBodyDark;
    }
  }
}

.wrapper {
  padding:1em;
}

.brand {
  width:740px;
  display:block;
  margin: 0 auto;
  z-index:3;
  position: relative;
}
</style>
