<template>
  <div class="adog--mainvis--bottomlayer">
    <div class="adog--mainvis--cloud">

    </div>
    <header class="adog--mainvis">
        <div class="adog--mainvis--placeholder">
          <div class="adog--mainvis--container">
              <!-- <h1>A DOG</h1> -->
          </div>
        </div>
    </header>
  </div>
</template>

<style scoped lang="scss">
.adog--mainvis {

  width:100%;
  background-image:url(../../src/assets/map.png);
  background-repeat:no-repeat;
  background-position:center;
  position:relative;
  z-index:1;
  // 2680px - 2560px
  background-size: 1340px auto;
  margin-top:10vh;

  @media screen and (max-width: 1280px) { 
    background-size: 104.6875% auto; 
    padding: 2vw 0px;
  }

  //2080
  .adog--mainvis--placeholder {
    padding-top:77.6119403%;
    position:relative;
    margin:0 auto;
    max-width:1280px;
    max-height:1040px;
    box-sizing:border-box;

    .adog--mainvis--container {
      width:100%;
      height:100%;
      position:absolute;
      top:0;
      left:0;
      // background-color:rgba(255,0,0,0.5);
      display:flex;
      align-items:center;
      justify-content: center;
    }
    @media screen and (min-width: 1281px) {
      width:1280px;
      height:1040px;
      padding:0;
    }


    
  }

}

$scaleRatio: 0.65;
@keyframes bgscroll { 0% {background-position: 0 0;} 100% {background-position: (-100vw * $scaleRatio) 0 ;}}


.adog--mainvis--cloud {
  // overflow:hidden;
  // background-color:$colorBackgroundSkyDay;
  background-image:url('../assets/cloud_full.png');
  background-size:(100vw * $scaleRatio) auto;
  background-repeat:repeat-x;
  animation: bgscroll 30s linear infinite;
  position:absolute;
  left:0;
  top:calc(-12vw * $scaleRatio);
  width:100%;
  padding-top:16.161718% * $scaleRatio;
  z-index:2;
}


.adog--mainvis--bottomlayer {
background-image:url('../assets/mainvis_dart.png');
background-size:170vw auto;
@media screen and (min-width: 1281px) { 
  background-size:1280px*1.7 auto;
}
background-position:center bottom;
background-repeat:repeat-x;
position:relative;
}
</style>
